<template>
  <div class="container">
    <div class="user-info">
      <div>
        <custom-icon :name="'icon-yonghuming'" :color="`#fff`" :cstyle="{
          borderRadius:'50%'
          }" :size="48" />
      </div>
      <div>
        用户姓名：{{ userInfo.name }}
        <div style="margin-top: 10px">
          电话号码：{{ geTel(userInfo.phone) }}
        </div>
      </div>
      <div>
        <button @click="logOut">退出登录</button>
      </div>
    </div>
    <div
      class="margin"
      v-for="(item, index) in menus"
      :key="item.id"
      @click="$router.push({ name: 'sub-menu', params: { parentId: item.id, parentName: item.name } })"
    >
      <div class="row row-align-center padding shadow-lg radius15" :class="bgColor.type1">
        <div class="col-20 font-lg font-bold">{{ item.name }}</div>
        <div class="col-4">
          <md-icon class="icon-lg" :name="icons[index] || 'firewall'" svg></md-icon>
        </div>
      </div>
    </div>
    <div class="margin" v-if="menus.length > 0">
      <div class="row row-align-center padding shadow-lg radius15" :class="bgColor.type2"
           @click="$router.push({ name: 'judge' })">
        <div class="col-16 font-lg font-bold">消防专家系统</div>
        <div class="col-4">
          <md-icon class="icon-xxl" name="fireFightingCar" svg></md-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as menuApi from '@/api/menu'
import { me } from '@/api/user'
import customIcon from '@/components/custom-icon/index.vue'

import '@/assets/svg/house.svg'
import '@/assets/svg/product.svg'
import '@/assets/svg/device.svg'
import '@/assets/svg/fire.svg'
import '@/assets/svg/fireFightingCar.svg'
import '@/assets/svg/firewall.svg'

export default {
  components: {
    customIcon,
  },
  data() {
    return {
      userInfo: {},
      menus: [],
      icons: ['house', 'product', 'device', 'firewall', 'fire'],
      // isPro: this.$store.getters['user/isPro'],
      isPro: true,
      // isSuperPro: this.$store.getters['user/isSuperPro'],
      isSuperPro: true,
    }
  },
  methods: {
    geTel(tel = '') {
      let reg = /^(\d{3})\d{4}(\d{4})$/
      return tel.replace(reg, '$1****$2')
    },
    logOut() {
      window.localStorage.removeItem('token')
      this.$toast.info('退出成功')
      setTimeout(() => {
        this.$router.replace({
          path: '/login',
        })
      }, 1500)
    },
  },
  computed: {
    bgColor() {
      if (this.isPro) {
        return {
          type1: 'color-bg-blue1',
          type2: 'color-bg-blue2',
        }
      }
      if (this.isSuperPro) {
        return {
          type1: 'color-bg-orange1',
          type2: 'color-bg-orange2',
        }
      }
      return {
        type1: 'color-bg-green1',
        type2: 'color-bg-green2',
      }
    },
  },
  created() {
    menuApi.list({ level: 0 }).then(res => (this.menus = res.data))
    me().then(res => {
      this.userInfo = res.data
    })
  },
}
</script>
<style lang="stylus" scoped>
.container
  height calc(100vh - 100px)
  overflow-y scroll
  margin-top 155px

  .icon-lg
    width 70px
    height 70px

  .icon-xxl
    width 200px
    height 200px

  .help
    position fixed
    width 100%
    bottom 140px

  >>> .md-button.link
    color #2f86f6

  .user-info
    display flex
    justify-content space-around
    align-items center
    padding 10px
    background-color #007aff
    color #fff
    position fixed
    z-index 100
    height 150px
    top 0
    width 100%

    button
      outline none
      background-color #fc6e51
      color #ffffff
      border none
      padding 10px
      border-radius 5px
</style>
